export const addressCreator = (data: any) => {
    const sub_building_name = data.sub_building_name;
    const building_name = data.building_name;
    const building_number = data.building_number;
    const dependent_road = data.dependent_road;
    const road = data.road;
    const dependent_locality = data.dependent_locality;
    const locality = data.locality;
    const town = data.town;

    return [sub_building_name, building_name, building_number, dependent_road, road, dependent_locality, locality, town].filter((el) => el).join(', ');
};